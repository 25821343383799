import { Component, OnInit, OnDestroy, HostListener, InjectionToken, Inject } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Subscription } from "rxjs";
import { PhxCsrfConfigService } from '@phoenix/ui/common';
import { UserAuthenticationDetails } from './feature-modules/login/user.authentication.details';
import { NavItem } from '@phoenix/ui/bread-crumb';
import { StartupService } from './core/services/startup.service';
import { Title, Meta } from '@angular/platform-browser';
import { InstanceService } from './core/services/instance.service';
import { AppConfig, APP_CONFIG} from './app.module';
import { PhxTranslateService } from '@phoenix/ui/dist/translate';

@Component({
  selector: 'dts-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public showMessage = this.startupService.checkBrowser();
  title = 'DtsUI app';
  public innerHeightNumber: any;
  userDto: any;
  subscribeBreadcrumb: Subscription;
  subscribeLogin: Subscription;
  instance: any;
  //Sets the Breadcrumb options for this view
  breadcrumbItems: NavItem[] = [];
  nonce: String;
  constructor(
    private userAuthenticationDetails: UserAuthenticationDetails,
    public router: Router,
    private route: ActivatedRoute,
    private startupService: StartupService,
    private instanceService: InstanceService,
    private phxCsrfConfigService: PhxCsrfConfigService,
    private titleService: Title,
    @Inject(APP_CONFIG) config: AppConfig,
    private translateService: PhxTranslateService, 
    private meta: Meta
  ) {
    console.log("CSP Default: " + this.startupService.cspDefault() + " CSP Style: " + this.startupService.cspStyle() + " CSP Script: " + this.startupService.cspScript() + " CSP Style: " + this.startupService.cspImg());
    this.translateService.use('en_US');
    this.meta.addTag({ name: 'PHX-CSP-NONCE', content: config.nonce });
    this.meta.addTag({
      httpEquiv: 'Content-Security-Policy',
      content: `default-src 'self' cookies-data.onetrust.io geolocation.onetrust.com privacyportal-bofa.my.onetrust.com cdn.cookielaw.org https://ah-1066693-001.sdi.corp.bankofamerica.com:22013 ` + this.startupService.cspDefault() + ` ;
     style-src 'self' 'nonce-${config.nonce}' ` + this.startupService.cspStyle() + ` ;
     script-src  'self' 'nonce-${config.nonce}' 'strict-dynamic' ` + this.startupService.cspScript() + ` ;
     img-src 'self' cdn.cookielaw.org  data: ` + this.startupService.cspImg() + ` ;`,
    });

    
    this.nonce = config.nonce;

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.breadcrumbItems = [];
      }
    });

    if (!this.subscribeBreadcrumb) {
      this.subscribeBreadcrumb = this.userAuthenticationDetails.breadcrumbEmitter.subscribe((items: any) => {
        this.breadcrumbItems = items;
      });
    }
    if (!this.subscribeLogin) {
      this.subscribeLogin = this.userAuthenticationDetails.invokeLoginEmitter.subscribe((response: any) => {
        this.userDto = undefined != this.userAuthenticationDetails.getTempUserObjString() ? JSON.parse(this.userAuthenticationDetails.getTempUserObjString()).userDto : '';
      });
    }
    else {

    }
    
    if (this.userAuthenticationDetails.getLoginStatus()) {
      this.router.navigate(['/home']);
    } else if (window.location.hash === '#/resetPassword') {
      this.router.navigate(['/login']);
    }
    if (userAuthenticationDetails.getCSRFToken() != null) {
      phxCsrfConfigService.setCsrfConfig({
        token: userAuthenticationDetails.getCSRFToken()
      });
    }
  }

  authDetails: any = this.userAuthenticationDetails;

  ngOnInit(): void {
    this.setInnerHeight();
    this.userDto = undefined != this.userAuthenticationDetails.getTempUserObjString() ? JSON.parse(this.userAuthenticationDetails.getTempUserObjString()).userDto : '';
    this.loadEnvironmentStyles();
  }

  ngOnDestroy() {
    if (this.subscribeLogin)
      this.subscribeLogin.unsubscribe();
    if (this.subscribeBreadcrumb)
      this.subscribeBreadcrumb.unsubscribe();
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle('B2Bi Portal ' + (newTitle.length > 0 ? '| ' + newTitle: ''));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setInnerHeight();
  }

  /**
   * Handle browser resize
   */
  setInnerHeight() {
    this.innerHeightNumber = (window.innerHeight - 320);
  }

  /**
   * Load internal vs external styles and scripts
   */
  loadEnvironmentStyles() {

    const headerElement = document.getElementsByTagName('head')[0];

    let script;

    if (!this.startupService.isInternalApp()) {
      script = document.createElement('script');
      script.nonce = this.nonce;
      script.setAttribute('nonce', this.nonce);
      console.log("nonce is: " + this.nonce);
      script.text = "let OT_KEY = '" + this.startupService.otKey() + 
      "'; let OT_HASH = '" + this.startupService.otHash() + 
      "'; let nonce = '" + this.nonce + "'"; //ad1a8c9a-e349-4115-86fe-a6ce0b9251ce-test      
      headerElement.appendChild(script);

      script = document.createElement('script');
      script.setAttribute('nonce', this.nonce);
      script.src = "assets/js/boa-cookies.js";
      headerElement.appendChild(script);
    }

    let node = document.createElement('link');
    node.href = "/PHXcms/public/content/b2bi/_assets/theme.css";
    node.rel = "stylesheet";

    headerElement.appendChild(node);
    
     if (this.startupService.getInstance() != null){
      let instanceFolder: string = this.startupService.getInstance();
      this.instance = this.instanceService.load(instanceFolder);
    }
    
  }
}